// import React, { useEffect } from 'react';
// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
// import './AnimationShrink.scss';

// function AnimationShrink({ children }) {
//   const boxVariant = {
//     visible: { scale: 1, transition: { duration: 0.8 } },
//     hidden: { scale: 0 }
//   };

//   const control = useAnimation();
//   const [ref, inView] = useInView({ threshold: 0 });

//   useEffect(() => {
//     if (inView) {
//       control.start("visible");
//     } else {
//       control.start("hidden");
//     }
//   }, [control, inView]);

//   return (
//     <div id='shrink-animation-style'>
//       <motion.div
//         className="box"
//         ref={ref}
//         variants={boxVariant}
//         initial="hidden"
//         animate={control}
//       >
//         {children}
//       </motion.div>
//     </div>
//   );
// }

// export default AnimationShrink;

import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import './AnimationShrink.scss';

function AnimationShrink({ children }) {
  const boxVariant = {
    visible: { scale: 1, transition: { duration: 0.8 } },
    hidden: { scale: 0 }
  };

  const control = useAnimation();
  const [ref, inView] = useInView({ threshold: 0 });
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    if (inView && !animationPlayed) {
      control.start("visible");
      setAnimationPlayed(true);
    }
  }, [control, inView, animationPlayed]);

  return (
    <div id='shrink-animation-style' ref={ref}>
      <motion.div
        className="box"
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default AnimationShrink;