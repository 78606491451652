import { DEFAULT_LANGUAGE, LANGUAGES } from "constants";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Create Context
const LanguageContext = createContext({});

// Create Context Provider
export const LanguageContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const params = useParams();
  const languageFromUrl = params.lang;
  const languageFromLocalStorage = localStorage.getItem("fuelpay-language");
  const [language, setLanguage] = useState(
    languageFromUrl || languageFromLocalStorage || DEFAULT_LANGUAGE
  );

  const handleSwitchLanguage = useCallback(() => {
    // switch between the 2 Available Languages
    let newLanguage = LANGUAGES.filter((lng) => lng !== language)[0];
    navigate(`/${newLanguage}`);
    setLanguage(newLanguage);
    localStorage.setItem("fuelpay-language", newLanguage);
  }, [language, navigate]);

  useEffect(() => {
    if (language === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.documentElement.setAttribute("lang", "ar");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      document.documentElement.setAttribute("lang", "en");
    }
  }, [language]);

  const value = useMemo(
    () => ({
      language,
      handleSwitchLanguage,
    }),
    [language, handleSwitchLanguage]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// create a hook to use the Context
export const useLanguageContext = () => useContext(LanguageContext);
