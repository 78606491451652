import React from "react";
import "./CustomCard.scss";
import { Card } from "react-bootstrap";

function CustomCard({title, img}) {
  return (
    <div id="custom-card" style={{width: "100%", height: "100%"}}>
      <Card className="text-center d-block p-4 shadow rounded-4 d-flex align-items-center justify-content-between" style={{width: "100%", height: "100%"}}>
        <Card.Img src={img} alt={title} className="test" />
        <Card.Body className="p-0">
          <Card.Title className="pt-4 text-secondary">{title}</Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CustomCard;
