import React from "react";
import { Container, Button } from "react-bootstrap";
import { useLanguage } from "hooks";
import "./Header.scss";

function Header() {
  const { language, handleSwitchLanguage } = useLanguage();

  return (
    <div id="header">
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <img
              src="/Images/newLogo.png"
              alt="logo"
              className="logo-style"
            />
          </div>

          <div className="langButton">
            <Button
              // as={Link}
              // to={`/${language === "ar" ? "en" : "ar"}`}
              onClick={handleSwitchLanguage}
              variant="primary"
            >
              <div>
                <h5 className="text-white ">
                  {language === "ar" ? "English" : "عربي"}
                </h5>
              </div>
            </Button>
          </div>
        </div>

        <div className="horizontal-line"/>


      </Container>
    </div>
  );
}

export default Header;
