import React, { useMemo } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { AnimationFade, AnimationFadeRANDL, AnimationShrink, CustomCard, Layout } from "components";
import "./Home.scss";
import { useLanguage } from "hooks";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const SlideItem = ({ slide, index }) => {
  const swiperSlide = useSwiperSlide();

  const getScaleY = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1.3;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1.15;
    }

    if (swiperSlide.isVisible) {
      return 1.05;
    }

    return 0.2;
  }, [
    swiperSlide.isActive,
    swiperSlide.isNext,
    swiperSlide.isPrev,
    swiperSlide.isVisible,
  ]);

  const getTiming = useMemo(() => {
    if (swiperSlide.isActive) {
      return 0.25;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 0.35;
    }

    return 0.35;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  const getZindex = useMemo(() => {
    if (swiperSlide.isActive) {
      return 1300;
    }

    if (swiperSlide.isPrev || swiperSlide.isNext) {
      return 1200;
    }

    return 1100;
  }, [swiperSlide.isActive, swiperSlide.isNext, swiperSlide.isPrev]);

  return (
    <div>
      <Card
        className={`card-style-custom ${swiperSlide.isActive ? "z-10000" : "z-1"} ${swiperSlide.isActive ? "bg-secondary" : "bg-white"} border border-secondary`}
        style={{
          position: "relative",
          transition: `all ${getTiming}s ease-in-out`,
          transform: `scale(${getScaleY}, ${getScaleY})`,
          zIndex: getZindex,
          opacity: swiperSlide.isVisible === true ? 1 : 0,
          borderRadius: "15px",
        }}
      >
        <Card.Body>
          <h6 className={`${swiperSlide.isActive ? "text-white fs-4" : "text-secondary fs-8"} fw-bolder`}>{slide.name}</h6>
          <p className={`${swiperSlide.isActive ? "text-white" : "text-secondary"} fs-10 mt-2`}>{slide.description}</p>

          <h4 className={`${swiperSlide.isActive ? "text-white fs-2" : "text-secondary"} my-4 fw-bolder`}>{slide.price}</h4>

          <ul className="m-0">
            {slide.features.map((i, idx) => (
              <li key={idx} className={`${swiperSlide.isActive ? "text-white" : "text-secondary"} fs-10 mb-3`}>
                <div className="d-flex align-items-center justify-content-center gap-2">
                  {i.title}
                  <img src={`${swiperSlide.isActive ? "/Images/priceIcon1.png" : "/Images/priceIcon.png"}`} alt="priceIcon" className="feature-icon-style text-primary" />
                </div>
              </li>
            ))}
          </ul>

          <Button href="/" variant={`${swiperSlide.isActive ? "bg11" : "secondary"}`} className="rounded-5 mt-5 mb-3 fw-bold text-white">{slide.payTitle}</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

const Home = () => {
  const { language, translation } = useLanguage();

  const slides = [
    {
      id: "1",
      name: translation.packageName,
      description: translation.packageDescription,
      price: "000$",
      features: [
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
      ],
      payTitle: translation.buyNow,
    },
    {
      id: "2",
      name: translation.packageName,
      description: translation.packageDescription,
      price: "000$",
      features: [
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
      ],
      payTitle: translation.buyNow,

    },
    {
      id: "3",
      name: translation.packageName,
      description: translation.packageDescription,
      price: "000$",
      features: [
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
        {
          title: translation.packageSpecification,
        },
      ],
      payTitle: translation.buyNow,
    },
    // {
    //   id: "4",
    //   name: "4اسم الباقة",
    //   description: "وصف لهذه الباقة",
    //   price: "000$",
    //   features: [
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //   ],
    //   payTitle: "اشتري الان",

    // },
    // {
    //   id: "5",
    //   name: "5اسم الباقة",
    //   description: "وصف لهذه الباقة",
    //   price: "000$",
    //   features: [
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //   ],
    //   payTitle: "اشتري الان",

    // },
    // {
    //   id: "6",
    //   name: "6اسم الباقة",
    //   description: "وصف لهذه الباقة",
    //   price: "000$",
    //   features: [
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //   ],
    //   payTitle: "اشتري الان",
    // },
    // {
    //   id: "7",
    //   name: "7اسم الباقة",
    //   description: "وصف لهذه الباقة",
    //   price: "000$",
    //   features: [
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //   ],
    //   payTitle: "اشتري الان",
    // },
    // {
    //   id: "8",
    //   name: "8اسم الباقة",
    //   description: "وصف لهذه الباقة",
    //   price: "000$",
    //   features: [
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //     {
    //       title: "خصائص هذه الباقه",
    //     },
    //   ],
    //   payTitle: "اشتري الان",
    // },
  ];

  const cardsData = [
    {
      title: translation.digitalCards,
      img: "/Images/cardImg4.png",
    },
    {
      title: translation.plasticCards,
      img: "/Images/cardImg3.png",
    },
    {
      title: translation.qrBarcode,
      img: "/Images/cardImg2.png",
    },
    {
      title: translation.smartSlides,
      img: "/Images/cardImg1.png",
    },
    {
      title: translation.rfidTag,
      img: "/Images/cardImg7.png",
    },
    {
      title: translation.handWatches,
      img: "/Images/cardImg6.png",
    },
    {
      title: translation.driverKeys,
      img: "/Images/cardImg5.png",
    },
  ];

  return (
    <Layout>
      <div id="home-page" className="">
        <div className="first-home-section">
          <Container>
            <Row className="align-items-center g-4">
              <Col xs={12} md={7} lg={8}>
                <AnimationFadeRANDL direction={`${language == "ar" ? "ltr" : "rtl"}`}>
                  <div className="div-content-style">
                    <h4 className="text-primary fw-bolder">{translation.firstSectionTitle}</h4>

                    <p className="mt-3 text-secondary">
                      {translation.firstSectionDescription}
                    </p>
                  </div>
                </AnimationFadeRANDL>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <AnimationFadeRANDL direction={`${language == "ar" ? "rtl" : "ltr"}`}>
                  {language === "ar" ? <img src="/Images/img1.png" alt="img1" width="100%" height="auto" /> : <img src="/Images/img1En.png" alt="img1En" width="100%" height="auto" />}
                </AnimationFadeRANDL>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="second-home-section">
          <Container>
            <Row className="align-items-center g-4">
              <Col xs={12} md={5} lg={4} xl={3}>
                <AnimationFadeRANDL direction={`${language == "ar" ? "ltr" : "rtl"}`}>
                  {language === "ar" ? <img src="/Images/img2.png" alt="img2" width="100%" height="auto" /> : <img src="/Images/img2En.png" alt="img2En" width="100%" height="auto" />}
                </AnimationFadeRANDL>
              </Col>
              <Col xs={12} md={7} lg={8} xl={9}>
                <AnimationFadeRANDL direction={`${language == "ar" ? "rtl" : "ltr"}`}>
                  <div className="div-content-style">
                    <h4 className="text-primary fw-bolder">{translation.secondSectionTitle}</h4>

                    {/* <p className="mt-3 text-secondary fw-bolder">{translation.secondSectionDescription}</p> */}
                    <p
                      className="mt-3 text-secondary fw-bolder"
                      dangerouslySetInnerHTML={{
                        __html: translation.secondSectionDescription,
                      }}
                    />
                  </div>
                </AnimationFadeRANDL>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="third-home-section">
          <Container>
            <Row className="align-items-center g-4">
              <Col xs={12}>
                <AnimationFade>
                  <div className="div-content-style">
                    <h4 className="text-primary fw-bolder">{translation.thirdSectionTitle}</h4>

                    <p className="mt-3 text-secondary fw-bolder">{translation.thirdSectionDescription}</p>
                  </div>
                </AnimationFade>
              </Col>
              <Col xs={12}>
                <AnimationFade>
                  <div className="mt-4">
                    {language === "ar" ? <img src="/Images/img3.png" alt="img3" width="100%" height="auto" /> : <img src="/Images/img3En.png" alt="img3En" width="100%" height="auto" />}
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="four-home-section">
          <Container>
            <Row className="align-items-center g-4">
              <Col xs={12}>
                <AnimationFade>
                  <h4 className="text-primary fw-bolder">{translation.features}</h4>
                </AnimationFade>
              </Col>
              <div className="div-content-style">
                <Row className="g-4">
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder">{translation.feature1}</li>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder">{translation.feature2}</li>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder">{translation.feature3}</li>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder">{translation.feature4}</li>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder">{translation.feature5}</li>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6}>
                    <AnimationFade>
                      <li className="text-secondary fw-bolder m-0 p-0">{translation.feature6}</li>
                    </AnimationFade>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </div>

        <div className="five-home-section">
          <Container>
            <Row className="justify-content-center">
              <Col xs={11} sm={10}>
                <AnimationFade>
                  <div className="my-5 position-relative">
                    <img src="/Images/img4New.png" alt="img4" width="100%" height="auto" />
                    <h4 className="title-center-style">{translation.targetGroups}</h4>

                    <div>
                      <h4 className="title1-style">{translation.individuals}</h4>
                      <img src="/Images/1.gif" alt="gif1" className="gif1-style" />
                    </div>

                    <div>
                      <h4 className="title2-style">{translation.companies}</h4>
                      <img src="/Images/2.gif" alt="gif2" className="gif2-style" />
                    </div>

                    <div>
                      <h4 className="title3-style">{translation.taxiVehicles}</h4>
                      <img src="/Images/3.gif" alt="gif3" className="gif3-style" />
                    </div>

                    <div>
                      <h4 className="title4-style">{translation.schools}</h4>
                      <img src="/Images/4.gif" alt="gif4" className="gif4-style" />
                    </div>

                    <div>
                      <h4 className="title5-style">{translation.gasStations}</h4>
                      <img src="/Images/5.gif" alt="gif5" className="gif5-style" />
                    </div>
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="six-home-section">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <AnimationFade>
                  <div className="my-5 position-relative">
                    {language === 'ar' ? <img src="/Images/img5.png" alt="img5" width="100%" height="auto" className="d-none d-lg-block" /> : <img src="/Images/img5En.png" alt="img5En" width="100%" height="auto" className="d-none d-lg-block" />}
                    {language === 'ar' ? <img src="/Images/img5tablet.png" alt="img5tablet" width="100%" height="auto" className="d-none d-md-block d-lg-none" /> : <img src="/Images/img5Entablet.png" alt="img5Entablet" width="100%" height="auto" className="d-none d-md-block d-lg-none" />}
                    {language === 'ar' ? <img src="/Images/img5mobile.png" alt="img5mobile" width="100%" height="auto" className="d-block d-md-none" /> : <img src="/Images/img5Enmobile.png" alt="img5Enmobile" width="100%" height="auto" className="d-block d-md-none" />}
                    <h4 className="title-center-style">{translation.smartApplication}</h4>
                    <h4 className="title1-style">{translation.customerServiceSystem}</h4>
                    <h4 className="title2-style">{translation.selfService}</h4>

                    <h4 className="title3-style">{translation.provision}</h4>
                    <h4 className="title4-style">{translation.control}</h4>
                    <h4 className="title5-style">{translation.monitoring}</h4>
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="seven-home-section">
          <Container>
            <Row>
              <Col xs={12}>
                <h4 className="text-primary fw-bolder">{translation.smartWaysToKnow}</h4>
              </Col>
              <Col xs={12}>
                <div className="mt-4">
                  <Row className="g-5 justify-content-center">
                    {cardsData.map((item, idx) => (
                      <Col xs={12} sm={6} md={4} lg={3} xl={3} key={idx}>
                        <AnimationFadeRANDL direction={`${language == "ar" ? "rtl" : "ltr"}`} index={idx}>
                          <CustomCard title={item.title} img={item.img} />
                        </AnimationFadeRANDL>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="eight-home-section">
          <AnimationShrink>
            <Container>
              <div className="text-center">
                <Row className="justify-content-center">
                  <Col xs={12}>
                    <div className="title-screen">
                      <h4 className="text-primary fw-bolder">{translation.pricePackages}</h4>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={10} xl={9}>
                    <div className="h-100 my-5">
                      {/* <Swiper
                      dir="ltr"
                      watchSlidesProgress
                      centeredSlides
                      centeredSlidesBounds
                      centerInsufficientSlides
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        576: {
                          slidesPerView: 3, // Adjust this value as needed
                        },
                        768: {
                          slidesPerView: 3, // Adjust this value as needed
                        },
                      }}
                      spaceBetween={0}
                      slidesPerGroup={1}
                      loop={true}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                          <SlideItem slide={slide} />
                        </SwiperSlide>
                      ))}
                    </Swiper> */}
                      <Swiper
                        dir={language === "ar" ? "ltr" : "rtl"}
                        watchSlidesProgress
                        centeredSlides
                        centeredSlidesBounds
                        centerInsufficientSlides
                        // allowTouchMove={false}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                          },
                          767: {
                            slidesPerView: 3,
                            enabled: false
                          },
                        }}
                        spaceBetween={0}
                        slidesPerGroup={1}
                      // loop={true}
                      // navigation={true}
                      // modules={[Navigation]}
                      // className="mySwiper"
                      >
                        {slides.map((slide) => (
                          <SwiperSlide virtualIndex={slide.id}>
                            <SlideItem slide={slide} index={slide.id} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </AnimationShrink>
          <div className="background-price" />
        </div>

        <div className="nine-home-section">
          <Container>
            <Row>
              <Col xs={12}>
                <AnimationFade>
                  <div className="position-relative">
                    <img src="/Images/img7.png" alt="img7" width="100%" height="auto" />

                    <h2 className="title1">{translation.diesel}</h2>
                    <h2 className="title2">{translation.gasoline}</h2>
                    <h2 className="title3">{translation.electric}</h2>
                    <h2 className="title4">{translation.gas}</h2>
                    <h2 className="title5">{translation.hydrogen}</h2>
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
