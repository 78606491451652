// import React, { useEffect, useState } from 'react';
// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
// import './AnimationFadeRANDL.scss';

// function AnimationFadeRANDL({ children, direction, index }) {
//   const boxVariant = {
//     visible: { opacity: 1, x: 0, transition: { duration: 0.8, delay: index * 0.2 } },
//     hidden: { opacity: 0, x: direction === 'rtl' ? -250 : 250, transition: { duration: 1.3, delay: 0.1 } }
//   };

//   const control = useAnimation();
//   const [ref, inView] = useInView();

//   const [scrollDirection, setScrollDirection] = useState('down');

//   useEffect(() => {
//     const handleScroll = () => {
//       const currentPosition = window.scrollY;

//       if (currentPosition > lastScrollPosition) {
//         setScrollDirection('down');
//       } else {
//         setScrollDirection('up');
//       }

//       lastScrollPosition = currentPosition;
//     };

//     let lastScrollPosition = window.scrollY;

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     if (inView && scrollDirection === 'down') {
//       control.start("visible");
//     } else if (scrollDirection === 'up') {
//       control.start("visible");
//     } else {
//       control.start("hidden");
//     }
//   }, [control, inView, scrollDirection]);

//   return (
//     <div id='fade-animation-style'>
//       <motion.div
//         className="box"
//         ref={ref}
//         variants={boxVariant}
//         initial="hidden"
//         animate={control}
//       >
//         {children}
//       </motion.div>
//     </div>
//   );
// }

// export default AnimationFadeRANDL;

import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import './AnimationFadeRANDL.scss';

function AnimationFadeRANDL({ children, direction, index }) {
  const boxVariant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, delay: index * 0.2 } },
    hidden: { opacity: 0, x: direction === 'rtl' ? -250 : 250, transition: { duration: 1.3, delay: 0.1 } }
  };

  const control = useAnimation();
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animationPlayed) {
      control.start("visible");
      setAnimationPlayed(true);
    }
  }, [control, inView, animationPlayed]);

  return (
    <div id='fade-animation-style' ref={ref}>
      <motion.div
        className="box"
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default AnimationFadeRANDL;