import React from "react";

import "./Footer.scss";

function Footer() {
  return (
    <div id="footer">

      <div className="logo-section">
        <img
          src="/Images/newLogo.png"
          alt="logo"
        />
      </div>

      <div className="last-section" />
    </div>
  );
}

export default Footer;
